const _getSliderFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'animation_speed',
        'slide_speed',
        'slides_to_display',
        'slides_to_display_mobile',
        'slides_to_display_tablet',
        'slides_to_scroll',
        'slides_to_scroll_mobile',
        'slides_to_scroll_tablet',
        'show_navigation',
        'show_menu'
    ];
};

const _getSlideFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'display_title',
        'slide_link',
        'slide_link_text'
    ];
};

export default {
    'Query/Slider': {
        'member-function': {
            _getSlideFields,
            _getSliderFields
        }
    }
}